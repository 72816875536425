<template>
  <div class="my-biography">
    <v-container>
      <v-row v-if="!$store.state.gallery.open">
        <v-col class="text-center" cols="12">
          <div class="tw-toggle">
            <input id="rdo-personal" type="radio" name="toggle" value="false" :checked="content_type === 0" @click="checkContentType(0)" />
            <label for="rdo-personal" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccount}" class="mx-auto">
                {{ mdiAccount }}
              </v-icon>
            </label>
            <input id="rdo-both" type="radio" name="toggle" value="-1" @click="checkContentType(-1)" :checked="content_type === -1" />
            <label for="rdo-both" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountMultiple}" class="mx-auto">
                {{ mdiAccountMultiple }}
              </v-icon>
            </label>
            <input id="rdo-professional" type="radio" name="toggle" value="true" @click="checkContentType(1)" :checked="content_type === 1" />
            <label for="rdo-professional" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountTie}" class="mx-auto">
                {{ mdiAccountTie }}
              </v-icon>
            </label>
            <span></span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!$store.state.gallery.open">
        <div class="d-flex justify-center wrapper-menu">
          <!-- ### Menu Mobile ### -->
          <template v-if="$vuetify.breakpoint.mdAndDown">
            <v-list class="mobile--menu-list">
              <v-list-group
                :value="false"
              >
                <template v-slot:activator>
                  <v-list-item-title>Categories</v-list-item-title>
                </template>

                <template v-if="!content_type || content_type === -1">
                  <div v-for="(item, i) in categories" :key="`system-${i}`">
                    <v-list-item class="single_item" link v-if="!item.children" @click="searchCategory(item.id, 1)"><span>-</span> {{ item.name }}</v-list-item>
                    <div v-else>
                      <v-list-group
                        :value="false"
                        no-action
                        sub-group
                      >
                        <template v-slot:activator>
                          <v-list-item-title @click="searchCategory(item.id, 1)">{{ item.name }}</v-list-item-title>
                        </template>
                        <div v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                          <v-list-item class="single_item" link v-if="!itemL1.children" @click="searchCategory(itemL1.id, 1)"><span>-</span> {{ itemL1.name }}</v-list-item>
                          <v-list-group
                            :value="false"
                            no-action
                            sub-group
                            v-else
                          >
                            <template v-slot:activator>
                              <v-list-item-title @click="searchCategory(itemL1.id, 1)">{{ itemL1.name }}</v-list-item-title>
                            </template>
                            <v-list-item class="single_item" link v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`" @click="searchCategory(itemL2.id, 1)"><span>-</span> {{ itemL2.name }}</v-list-item>
                            <v-divider></v-divider>
                          </v-list-group>
                        </div>
                        <v-divider></v-divider>
                      </v-list-group>
                    </div>
                  </div>
                </template>

                <template v-if="content_type || content_type === -1">
                  <div v-for="(item, i) in professionalCategories" :key="`system-${i}`">
                    <v-list-item class="single_item" link v-if="!item.children" @click="searchCategory(item.id, 1)"><span>-</span> {{ item.name }}</v-list-item>
                    <div v-else>
                      <v-list-group
                        :value="false"
                        no-action
                        sub-group
                      >
                        <template v-slot:activator>
                          <v-list-item-title @click="searchCategory(item.id, 1)">{{ item.name }}</v-list-item-title>
                        </template>
                        <div v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                          <v-list-item class="single_item" link v-if="!itemL1.children" @click="searchCategory(itemL1.id, 1)"><span>-</span> {{ itemL1.name }}</v-list-item>
                          <v-list-group
                            :value="false"
                            no-action
                            sub-group
                            v-else
                          >
                            <template v-slot:activator>
                              <v-list-item-title @click="searchCategory(itemL1.id, 1)">{{ itemL1.name }}</v-list-item-title>
                            </template>
                            <v-list-item class="single_item" link v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`" @click="searchCategory(itemL2.id, 1)"><span>-</span> {{ itemL2.name }}</v-list-item>
                            <v-divider></v-divider>
                          </v-list-group>
                        </div>
                        <v-divider></v-divider>
                      </v-list-group>
                    </div>
                  </div>
                </template>
                <v-divider></v-divider>
                <v-list-item class="single_item" v-if="!content_type" @click="searchCategory(-1, 1)"
                  v-bind:class="{ 'active': categorySelected === '' }">
                  - All
                </v-list-item>

                <v-list-item link class="single_item" v-if="content_type !== -1" @click="$store.commit('showCustomCategories', true);"
                  v-bind:class="{ 'active': categorySelected === '' }">
                  - Customize
                </v-list-item>
              </v-list-group>
            </v-list>
          </template>

          <div class="inner-wrapper-menu" v-if="active && !$vuetify.breakpoint.mdAndDown">
            <ul class="list-menu clearfix" v-if="!content_type || content_type === -1">
              <li v-for="(item) in categories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="!content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
              <li v-if="content_type !== -1">
                <v-btn @click="$store.commit('showCustomCategories', true);" elevation="2">
                  Customize
                </v-btn>
              </li>
            </ul>

            <ul class="list-menu clearfix" v-if="content_type || content_type === -1">
              <li v-for="(item) in professionalCategories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
              <li v-if="content_type !== -1">
                <v-btn @click="$store.commit('showCustomCategories', true);" elevation="2">
                  Customize
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
      </v-row>

      <!-- <v-row>
        <div class="d-flex justify-center wrapper-menu">
          <div class="inner-wrapper-menu">
            <v-btn elevation="2" large>Biography Scroll</v-btn>
            <v-btn elevation="2" large>Biography Tree</v-btn>
            <v-btn elevation="2" large>Biography Connections</v-btn>
            <v-btn elevation="2" large>Biography Book</v-btn>
          </div>
        </div>
      </v-row> -->

      <v-row justify="center" class="mt-10 mb-6 position-relative">
        <v-container>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-subtitle-1 text-center wrapper-content"
              cols="12"
            >
              <v-timeline v-if="active && files.length">
                <v-timeline-item
                  v-for="(file, i) in files"
                  :key="`biography-${i}`"
                  large
                >
                  <template v-slot:icon v-if="!$vuetify.breakpoint.mdAndDown">
                    <span class="date-item">{{ formatDate(file.updated_at.split('T')[0]) }}</span>
                    <v-icon
                      :class="{'alternate-icon-small': !mdiBookOpenVariant}"
                      class="mx-auto icon-info-date"
                      dark
                    >
                      {{ mdiBookOpenVariant }}
                    </v-icon>

                    <div class="wrapper-icons">
                      <v-icon
                        :class="{'alternate-icon-small': !mdiSquareEditOutline}"
                        class="mx-auto icon-edit"
                        @click="openEdit(file)"
                        v-if="!$vuetify.breakpoint.mdAndDown"
                      >
                        {{ mdiSquareEditOutline }}
                      </v-icon>
                      <v-icon @click="showDeleteBtns(true, i)"
                        :class="{'alternate-icon-small': !mdiTrashCan}"
                        v-if="!deletingFile[i]"
                        class="mx-auto icon-trash"
                      >
                        {{ mdiTrashCan }}
                      </v-icon>
                      <v-icon
                        :class="{'alternate-icon-small': !mdiShareVariantOutline}"
                        class="mx-auto icon-trash"
                      >
                        {{ mdiShareVariantOutline }}
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:opposite>
                    <div class="v-timeline-item__body box-slider">
                      <v-card class="elevation-2" @click="showModal(file);modals.file.created_at = file.created_at.split('T')[0];">
                        <div v-if="file.view_type && file.related_files.length > 1">
                          <img :src="`${file.related_files[0]}`" :class="`rotate_images__position_${file.rotate_position[0]}`"  />
                        </div>
                        <div v-else>
                          <!--
                            :cycle="!$store.state.content_type"
                          -->
                          <v-carousel v-if="file.related_files.length > 1" height="100%"
                          >
                            <v-carousel-item
                              v-for="(item, ic) in file.related_files"
                              :key="ic"
                            >
                              <img v-if="isImage(item)" :src="`${item}`" :class="`rotate_images__position_${file.rotate_position[ic]}`" />
                              <video width="100%" height="100%" controls v-else>
                                <source :src="item" type="video/mp4">
                                Your browser does not support the video tag.
                              </video>
                            </v-carousel-item>
                          </v-carousel>
                          <div v-else>
                            <img v-if="isImage(file.related_files[0])" :src="`${file.related_files[0]}`" :class="`rotate_images__position_${file.rotate_position[0]}`" />
                            <video width="100%" height="100%" controls v-else>
                              <source :src="file.related_files[0]" type="video/mp4">
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>

                        <v-card-text class="item-description" v-if="$vuetify.breakpoint.mdAndDown">
                          <v-container style="height: 400px;" v-if="deletingFile[i]">
                            <v-row
                              class="fill-height"
                              align-content="center"
                              justify="center"
                            >
                              <v-col v-if="!loading"
                                class="text-subtitle-1 text-center"
                                cols="12"
                              >
                                {{ result ? 'The content has been deleted.' : 'Are you sure you want to delete this content?' }}
                              </v-col>
                              <v-col cols="12" class="justify-content-center wrapper-buttons-center" v-if="!loading && !result">
                                <v-btn color="primary" @click="submitDelete(file.id)">Delete</v-btn>
                                <v-btn @click="showDeleteBtns(false, i)">Cancel</v-btn>
                              </v-col>

                              <v-col v-if="loading"
                                class="text-subtitle-1 text-center"
                                cols="12"
                              >
                                Deleting content...
                              </v-col>
                              <v-col cols="6" v-if="loading">
                                <v-progress-circular
                                  :size="70"
                                  :width="7"
                                  color="primary"
                                  indeterminate
                                ></v-progress-circular>
                              </v-col>
                            </v-row>
                          </v-container>

                          <div class="wrapper-text" v-if="!deletingFile[i]" @click="showModal(file);modals.file.created_at = file.created_at.split('T')[0];">
                            <div class="v-card__title">
                              {{file.name }}
                            </div>
                            <div>
                              <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                              <div class="v-card__text" v-html="subString(file.alternative_text)"></div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </template>
                  <v-card class="elevation-2" v-if="!$vuetify.breakpoint.mdAndDown">
                    <v-card-text class="item-description">
                      <v-container style="height: 400px;" v-if="deletingFile[i]">
                        <v-row
                          class="fill-height"
                          align-content="center"
                          justify="center"
                        >
                          <v-col v-if="!loading"
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            {{ result ? 'The content has been deleted.' : 'Are you sure you want to delete this content?' }}
                          </v-col>
                          <v-col cols="12" class="justify-content-center wrapper-buttons-center" v-if="!loading && !result">
                            <v-btn color="primary" @click="submitDelete(file.id)">Delete</v-btn>
                            <v-btn @click="showDeleteBtns(false, i)">Cancel</v-btn>
                          </v-col>

                          <v-col v-if="loading"
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            Deleting content...
                          </v-col>
                          <v-col cols="6" v-if="loading">
                            <v-progress-circular
                              :size="70"
                              :width="7"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </v-col>
                        </v-row>
                      </v-container>

                      <div class="wrapper-text" v-if="!deletingFile[i]" @click="showModal(file);modals.file.created_at = file.created_at.split('T')[0];">
                        <div class="v-card__title">
                          {{file.name }}
                        </div>
                        <div>
                          <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                          <div class="v-card__text" v-html="subString(file.alternative_text)"></div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <!-- <div v-else class="text-subtitle-1 text-center">
                <span v-if="!loading">There are no content to show.</span>

                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                  v-if="loading"
                ></v-progress-circular>
              </div> -->

              <v-container style="height: 200px;" v-if="!files.length">
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col class="text-subtitle-1 text-center" cols="12" v-if="!loading">There are no content to show.</v-col>
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                    v-if="loading"
                  ></v-progress-circular>
                </v-row>
              </v-container>


              <a href="javascript:;" @click="searchCategory(-1, 1);" v-if="active && files.length" class="show-all-years">Show All</a>
              <ul class="lst-timeline" v-if="active && files.length">
                <li v-for="(year, iy) in Object.keys(timeline)" :key="iy">
                  <a href="javascript:;" @click="getFiles(year);" :class="{'active': year === activeYear}">
                    {{ year }}
                    <v-icon class="mx-auto">{{ mdiChevronDown }}</v-icon>
                  </a>
                  <ul :class="{'active': year === activeYear}">
                    <li v-for="(month, im) in timeline[year]" :key="im"><a :class="{'active': month === activeMonth}" href="javascript:;" @click="getFiles(year, month)">-{{ months[parseInt(month, 10)-1] }}</a></li>
                  </ul>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
        <div class="pagination" v-if="active && files.length">
          <span>{{this.pagination }} of {{ this.qtdPages }} pages</span>
          <v-btn v-if="this.pagination < this.qtdPages"
            @click="getNextPage()" color="primary"
            :loading="loading4"
            :disabled="loading4">
            Load more
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>{{ mdiCached }}</v-icon>
              </span>
            </template>
          </v-btn>
        </div>

        <v-btn @click="goTop()" color="primary" class="btn-up" dark fab v-if="active && files.length">
          <v-icon>
            {{ mdiArrowUpBold }}
          </v-icon>
        </v-btn>
      </v-row>

      <!-- MODALS -->
      <ModalUpload class="mb-6" v-if="$store.state.modal_upload" :content_type="content_type" :categories="content_type ? professionalCategories : categories" :categoriesArray="categoriesArrayId" @getCategories="getCatgContentType" @newFile="newFile()" @closingModal="$store.commit('showUploadModal', false)" />
      <ModalDetails :actions="true" class="mb-6" v-if="modals.content" :modals="modals" @closingModal="modalClose" @openEdit="openEdit" @openZoom="openZoom" />
      <ModalEdit class="mb-6" v-if="$store.state.modal_edit" :content_type="content_type" :categories="content_type ? professionalCategories : categories" :categoriesArray="categoriesArrayId" :tagsFull="tags" :modals="modals" @closingModal="$store.commit('showEditModal', false)" @getCategories="getCatgContentType" />
      <ModalZoom class="mb-6" v-if="modals.zoom" :modals="modals" @closingModal="modalClose" />

      <!-- <Agreements class="mb-6" /> -->
      <ModalCustomCategories v-if="$store.state.customCategories" :content_type="content_type" :categories="content_type ? professionalCategories_full : categories_full" :categoriesArray="categoriesArray" @getCategories="getCatgContentType" />
      <!-- ###### -->
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mdiChevronDown, mdiCached, mdiCloudUpload, mdiArrowUpBold, mdiBookOpenVariant, mdiSquareEditOutline, mdiTrashCan, mdiShareVariantOutline, mdiAccountTie, mdiAccount, mdiAccountMultiple, mdiListStatus } from '@mdi/js';
import ModalUpload from '@/components/modals/ModalUpload.vue'
import ModalDetails from '@/components/modals/ModalDetails.vue'
import ModalEdit from '@/components/modals/ModalEdit.vue'
import ModalZoom from '@/components/modals/ModalZoom.vue'
import ModalCustomCategories from '@/components/modals/ModalCustomCategories.vue'

// import Agreements from '@/components/Agreements.vue';

export default {
  name: 'Grid',
  directives: {
  },
  components: {
    ModalCustomCategories,
    ModalUpload,
    ModalEdit,
    ModalDetails,
    ModalZoom
  },
  data: () => ({
    /* Icons */
    mdiChevronDown,
    mdiCloudUpload,
    mdiAccountTie,
    mdiAccount,
    mdiAccountMultiple,
    mdiListStatus,
    mdiCached,
    mdiArrowUpBold,
    mdiBookOpenVariant,
    mdiSquareEditOutline,
    mdiTrashCan,
    mdiShareVariantOutline,
    /* ---- */
    deletingFile: [],
    result: false,
    activeYear: null,
    activeMonth: null,
    loading4: false,
    user: {
      id: 0,
    },
    active: 1,
    logged: true,
    pagination: 1,
    qtdPages: 1,
    limitItems: 10,
    images: [],
    orientation: 'vertical',
    modals: {
      content: false,
      edit: false,
      upload: false,
      zoom: false,
      title: '',
      description: '',
      gallery: true,
      file: {
        created_at: '',
        url: '',
        name: '',
      },
    },
    endpoint: process.env.VUE_APP_ROOT_API,
    fileUploaded: null,
    categories: [],
    categories_full: [],
    categoriesArray: [],
    categoriesArrayId: [],
    professionalCategories: [],
    professionalCategories_full: [],
    file: {
      url: '',
      related_files: [],
    },
    files: [],
    categorySelected: '',
    cmdCategory: [],
    loading: true,
    uploadPercentage: 0,
    resultUpload: false,
    networks: [
      { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f share-icon', color: '#1877f2' },
    ],
    tags: [],
    relatedFiles: [],
    content_type: 0,
    counterFlow: 0,

    /* Categories */
    message: {
      status: false,
      text: ''
    },
    timer: null,
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    timeline: [],
    timeout: null
  }),
  watch: {
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    isImage(filePath) {
      return filePath && filePath.indexOf('mp4') < 0;
    },
    goTop() {
      // window.scrollTo(0,0);
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${this.months[parseInt(month, 10) - 1]} ${year}`
    },
    getCatgContentType(val) {
      this.content_type = val;
      this.$store.commit('setContentType', val);
      this.getCategories();
    },
    checkContentType(val) {
      this.content_type = val;
      this.$store.commit('setContentType', val);
      this.searchCategory(-1, 1);
    },
    checkLogin() {
      this.$store.commit('setLogin', true);
      this.active = true;
      this.logged =  true;
      this.$store.commit('setUploadBiographies', true);
      this.searchCategory(-1, 1);
    },
    callPagination(page) {
      this.searchCategory(this.categorySelected, page);
    },
    openEdit(evt) {
      const self = this;
      this.modals.content = false;
      if(evt.file) {
        self.$set(self.modals, 'file', evt.file);
      } else {
        self.$set(self.modals, 'file', evt);
      }
      this.$store.commit('showEditModal', true);
    },
    openZoom() {
      const self = this;
      self.modalClose(false);
      setTimeout(() => {
        self.modals.zoom = true;
      }, 200);
    },
    modalClose(search) {
      this.$store.commit('showEditModal', false);
      this.modals.content = false;
      this.fbConverting = false;
      this.modals.upload = false;
      this.modals.zoom = false;
      if(search) {
        this.searchCategory(-1, 1);
      }
    },
    newFile() {
      const self = this;
      self.modalClose(true);
      setTimeout(() => {
        self.modals.upload = true;
      }, 200);
    },
    subString(value) {
      if(value !== undefined) {
        return value.substring(0, 540) + '...';
      } else {
        return value;
      }
    },
    searchCategory(value, page) {
      this.categorySelected = value !== -1 ? value : '';
      this.files = [];
      this.getCategories();
      this.pagination = page;
      this.timeline = [];
    },
    geoCodeReverse() {
      const self = this;
      const geocoder = new google.maps.Geocoder();

      self.maps.currentLocation = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      };

      geocoder.geocode({'location': self.maps.searchAddressInput}, (results, status) => {
        if (status === 'OK') {
          console.log(results);
        }
      });
    },
    getCategories() {
      const self = this;
      axios.get(`${self.endpoint}/getCategories`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        self.categories = [];
        self.categories_full = [];
        self.categoriesArray = [];
        self.categoriesArrayId = [];
        self.professionalCategories = [];
        self.professionalCategories_full = [];
        self.active_menu_categories = response.data.active_menu_categories;
        response.data.content.forEach(value => {
          if(value.type) {
            if (!value.content_type) {
              if(value.active) {
                self.categories.push(value);
                if(self.content_type === 0) {
                  self.categoriesArrayId.push(value.id);
                }
              }
              self.categories_full.push(value);
              self.categoriesArray.push(value.name);
            } else {
              if(value.active) {
                self.professionalCategories.push(value);
                if(self.content_type === 1) {
                  self.categoriesArrayId.push(value.id);
                }
              }
              self.professionalCategories_full.push(value)
              self.categoriesArray.push(value.name);
            }
          }
        });
        this.getFiles();
        self.tags = response.data.tags;
      })
    },
    getFiles(year, month) {
      this.$set(this, 'activeYear', year);
      this.$set(this, 'activeMonth', month);
      axios.get(`${this.endpoint}/getFiles?content_type=${this.content_type >= 0 ? this.content_type : ''}${year ? `&year=${year}` : ''}${month ? `&month=${month}` : ''}&active_menu_categories=${this.active_menu_categories}&category=${this.categorySelected}&order=asc&limit=${(Math.abs((this.pagination - 1)) * this.limitItems)},${(this.limitItems)}`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        this.qtdPages = Math.ceil(response.data.total_files / this.limitItems);
        localStorage.setItem('last_location', response.data.content[response.data.content.length - 1].location);
        // this.timeline = [];
        if(year) {
          this.files = [];
          this.deletingFile = [];
        }

        response.data.content.forEach(value => {
          this.deletingFile.push(false);
          const yearC = value.updated_at.split('T')[0].split('-')[0];
          const monthC = value.updated_at.split('T')[0].split('-')[1];

          if(this.categorySelected) {
            if(this.timeline[yearC]) {
              if(!this.timeline[yearC].includes(monthC)) {
                this.timeline[yearC].push(monthC);
            }
          } else {
              this.timeline[yearC] = [];
              this.timeline[yearC].push(monthC);
            }
          } else {
            response.data.filter_dates.forEach(valueFilter => {
              const yearF = valueFilter.split('-')[0];
              const monthF = valueFilter.split('-')[1];
             if(this.timeline[yearF]) {
                if(!this.timeline[yearF].includes(monthF)) {
                  this.timeline[yearF].push(monthF);
                }
              } else {
                this.timeline[yearF] = [];
                this.timeline[yearF].push(monthF);
              }
            });
          }

          if(value.related_files) {
            value.related_files = value.related_files;
          } else {
            value.related_files = [];
          }
          if(value.rotate_position) {
            value.rotate_position = value.rotate_position.split(', ');
          } else {
            value.rotate_position = [];
          }
          this.files.push(value);
        });
        this.loader = false;
      })
      .catch(() => { 
        /* ERROR */ 
        this.loader = false;
      });
    },
    getNextPage() {
      this.loader = 'loading4';
      // window.onscroll = () => {
      //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      //   if (bottomOfWindow) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.pagination++;
        this.getFiles();
      }, 300);
    },
    showModal(evt) {
      if(evt.file) {
        this.$set(this.modals, 'file', evt.file);
      } else {
        this.$set(this.modals, 'file', evt);
      }
      this.fbcounter = 0;
      this.facebookImgUrl = '';
      this.modals.content = true;
    },
    showDeleteBtns(status, index) {
      this.$set(this.deletingFile, index, status);
    },
    submitDelete(id) {
      const self = this;
      self.loading = true;

      console.log(id);


      axios.post(`${self.endpoint}/deleteFileInfos`, {
        file_id: id
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        self.loading = false;
        self.result = true;
        this.deletingFile = [];
        this.getFiles();
      });
    },
  }
};
</script>

<style scoped lang="scss">
.wrapper-menu {
  flex: 1;
  position: relative;
  .inner-wrapper-menu {
    text-align: center;
  }
  .v-btn {
    margin-bottom: 20px;
  }

  .v-btn:not(:last-child) {
    margin-right: 10px;
  }
}

.v-window.v-item-group.theme--dark.v-carousel img {
  width: 100%;
  height: auto;
  padding: 17px;
}

.date-item {
  width: 200%;
  display: block;
  position: absolute;
  top: -15%;
  z-index: 1;
}

.v-timeline {
  border-top: 1px solid #ccc;
  &::before {
    display: none !important;
  }
}

.v-timeline-item {
  position: relative;
  padding: 80px 0 60px;
  border-bottom: 1px solid #ccc;

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }

  &:nth-child(odd) {
    .box-slider {
      .v-card:before,
      .v-card:after {
        transform: rotate(180deg) !important;
        right: -10px !important;
        left: initial !important;
      }
    }
  }
  &:nth-child(even) {
    .box-slider {
      .v-card:before,
      .v-card:after {
        transform: rotate(0deg) !important;
        right: initial !important;
        left: -10px !important;
      }
    }
  }
}

.wrapper-text {
  text-align: left;
}

.wrapper-icons {
  width: 1161px;
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 1;

  .v-icon {
    padding: 0 5px;
    cursor: pointer;
  }
}

@media (min-width: 1264px) {
  .v-timeline-item__body .v-card {
    min-height: 351px;
  }
}

@media (min-width: 1904px) {
  .v-timeline-item__body .v-card {
    min-height: 415px;
  }
  .wrapper-icons {
    width: 1370px;
  }
}

.v-avatar-group {
  position: absolute;
  top: 25px;
  right: 5%;
  display: none;
}

.v-avatar-group>:not(:first-child) {
  margin-left: -0.8rem;
}

.box-slider {
  img {
    width: fit-content !important;
    height: fit-content !important;
    display: block;
    max-width: 100%;
    padding: 17px !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    max-height: 383px;
    border-radius: 8px;
  }
}

@media(max-width: 940px) {
  .v-timeline-item__divider {
    display: none !important;
  }

  .box-slider {
    img {
      position: static;
    }
  }
}



.list-menu {
  margin: 0 0px !important;
  padding: 0 !important;
  justify-content: center !important;
  display: flex !important;
  li {
    text-align: left;
    list-style: none;
    cursor: pointer;
    position: relative;
    margin: 10px 0;
    ul {
      display: none;
      position: absolute;
      left: 0;
      width: auto;
      max-width: 300px;
      background: #fff;
      box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%);
      padding: 0;
      border-radius: 6px;
      top: 70%;
      z-index: 5;

      color:rgba(94, 86, 105, 0.87);
      text-transform: uppercase;
      font-size: 0.875rem;
      font-family: Inter, sans-serif;
      font-weight: 500;

      li {
        padding: 0 20px 0 15px;
        display: block;
        min-width: 150px;
        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .arrow-icon {
        transform: rotate(-90deg);
        position: absolute;
        right: -2px;
      }

      ul {
        left: 100%;
        top: -10px;
      }
    }
    &:hover {
      text-decoration: underline;
      > ul {
        display: block;
      }
    }
  }
  > li {
    float: left;
    margin: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.wrapper-content {
  position: relative;
}

/* Component */
.lst-timeline  {
  position: fixed;
  top: 335px;
  right: calc(((100vw - 1392px) - 250px) / 2);
  z-index: 5;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 815px);

  > li {
    list-style: none;
    width: 100px;
    padding: 10px 0 10px 20px;
    margin: 0;
    text-align: center;
    font-size: 14px;

    .v-icon {
      transform: scale(0.8) translate(-4px, -2px)  rotate(0deg);
    }

    &:first-child {
      padding-top: 0;
    }

    a {
      transition: all 0.2s ease;
      position: relative;
      color: rgba(94, 86, 105, 0.68);
      text-decoration: none;
      font-weight: 500;
      &:hover {
        padding-left: 15px;
        color: #9155fd;
      }
      &.active {
        color: #9155fd;
        font-weight: bold;
        .v-icon {
          transform: scale(0.8) translate(-4px, -2px) rotate(180deg);
          color: #9155fd;
        }
      }
    }

    ul {
      margin: 0;
      padding-left: 10px;
      transition: all 0.3s ease-out;
      height: 0;
      overflow: hidden;

      &.active {
        height: auto;
        margin: 5px 0;
        // a {
        //   color: #9155fd;
        // }
      }
    }

    li {
      list-style: none;
      padding: 0;
    }
  }
}

.show-all-years {
  position: fixed;
  top: 285px;
  right: calc(((100vw - 1392px) - 250px) / 2);
  margin: 0;
  width: 100px;
  text-align: center;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  color:rgba(94, 86, 105, 0.68);
  text-decoration: none;
  z-index: 2;
  &:hover {
    color: #9155fd;
  }
}


.pagination {
  span {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}

.btn-up {
  position: absolute;
  right: 11px;
  bottom: 0px;
}
</style>
